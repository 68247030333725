<template>
    <div class="main-container">
        <div class="title">Ingresa a los juegos de inteligencia cramerianos</div>
        <div class="login-container">
            <img 
                src="../assets/escudo-cramer.png" 
                alt="escudo cramer" 
                class="logo-login mb-5"
            >
            <form class="form-login" @submit.prevent="restore()" >
                <div class="form__input-label-container mt-5">
                    <label for="user">Correo</label>
                    <input v-model="user" type="email" id="user" name="user">
                    <div v-if="errorMessage" class="error-msg">
                        {{errorMessage}}
                    </div>
                </div>
                <button class="login-button mt-5" type="submit">Enviar Correo</button>
                <router-link to="/login" class="btn-register" >
                                Regresar
                </router-link>
            </form>
        </div>
        <img class="login-lion" src="../assets/characters/teacher-cramer.png">
    </div>
</template>
<script>
import {auth} from '../firebase'
export default {
    data:()=>({
        user:'',
        password:'',
        errorMessage:''
    }),
    methods:{
        restore()
        {
            let self = this;
            self.errorMessage = ""
            if(this.user){
                auth.sendPasswordResetEmail(this.user).then(function() {
                // Email sent.
                self.errorMessage =  "Revise su correo y haga click en el enlace para restaurar su correo"
                }).catch(function(error) {
                // An error happened.
                if(error.code =="auth/user-not-found")
                {
                    self.errorMessage = "El usuario no existe"
                }
                else if(error.message=="There is no user record corresponding to this identifier. The user may have been deleted.")
                {
                    self.errorMessage = "No se encontró el usuario. El usuario posiblemente fue eliminado";
                }
                else{
                    self.errorMessage = error.message
                }
                //
                });
            }
            else{
                this.errorMessage = "ingrese su correo por favor."
            }

        }
    }
}
</script>
<style scoped>
.title{
    display:none;
}
.login-lion{
    display: none;
}
.login-container{
    background-color:#050259;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/bg-movil/bg-login.svg');
    background-repeat: no-repeat;
    background-position: center;
}
.logo-login{
    width: 40vh;
}
.form-login{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form__input-label-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.form__input-label-container label{
    color: white;
    font-size: 25px;
}
.form__input-label-container input{
    border-radius:5px;
    border:0px;
    outline: none;
    box-shadow: none;
    width: 99%;
    height: 2.5em;
}
.login-button{
    background-color:#F23827;
    border: white solid 1px;
    color:white;
    font-family: 'Teko', sans-serif;
    font-size: 30px;
    width: 100%;
    border-radius:10px;
}
.mt-5{
    margin-top:20px;
}
.mb-5{
    margin-bottom: 20px;
}
.login-button:hover{
    cursor: pointer;
}
input:focus, input.form-control:focus,button {

    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
.login__register-message{
    margin-top:2em;
    color:white;
}
.btn-register{
    color:aqua;
    margin-top: 1em;
}
.error-msg{
    margin-top:1em;
    color:tomato;
}
@media (min-width: 900px) and (orientation: landscape) {
    .login-container{
        background-color: #050259;
        box-shadow: white 3px 3px 10px;
    }
    .main-container{
        display: grid;
        grid-template-columns: 10% 20% 20% 10% 20% 20%;
        grid-template-rows: 10vh 80vh 10vh;
        background-color: #050259;
        background-image: url('../assets/bg-desktop/bg-login.svg');
        background-position-x: -10px;
        background-position-y: -500px;
    }
    .login-lion{
        display: block;
        width: 70vh;
        transform: translateY(10%);
        grid-column: 4 / 4;
        grid-row: 2 / 2;
    }
    .login-container{
        height: 80vh;
        grid-column: 2 / 2;
        grid-row:2 / 2;
    }
    .logo-login{
        width: 30vh;
    }
    .title{
        text-align: center;
        display: block;
        font-size: 3em;
        grid-column: 3 / 7;
        transform: translateY(40%);
        color:white;
    }
}
</style>